import React from "react";

import i18next from "i18next";

import fr_CA from "../i18n/fr_CA";
import en_CA from "../i18n/en_CA";

i18next.addResourceBundle("fr-CA", "usersApp", fr_CA);
i18next.addResourceBundle("en-CA", "usersApp", en_CA);

export const ProfilePageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: "login",
  routes: [
    {
      path: "/users/profile",
      component: React.lazy(() => import("./ProfilePage")),
    },
  ],
};
