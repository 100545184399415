import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "formulasApp", fr_CA);
i18next.addResourceBundle("en-CA", "formulasApp", en_CA);

export const FormulasConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Formulas",
  auth: "formula_management",
  routes: [
    {
      path: "/app/formulas/item/:itemId/:itemSlug?",
      component: React.lazy(() => import("./views/Formula")),
    },
    {
      path: "/app/formulas/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/formulas",
      component: () => <Redirect to="/app/formulas/list" />,
    },
  ],
};
