import React from "react";
import _ from "@lodash";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { addValidationRule, withFormsy } from "formsy-react";


function FileInput(props) {
     const importedProps = _.pick(props, [
      "autoComplete",
      "autoFocus",
      "children",
      "className",
      "defaultValue",
      "disabled",
      "FormHelperTextProps",
      "fullWidth",
      "id",
      "inputlabelprops",
      "inputProps",
      "InputProps",
      "inputRef",
      "label",
      "multiline",
      "name",
      "onBlur",
      "onChange",
      "onFocus",
      "placeholder",
    
      "rows",
      "rowsMax",
      "select",
      "SelectProps",
      "type",
      "variant",
      "margin",
    ]);
  
    const {  setValue, onChange } = props;
    const value = props.value || "";
  
    function changeValue(event) {
      let value = event.currentTarget.files[0];
    
        if (value === "") value = null;
        
     
      setValue(value);
      if (onChange) {
        onChange(event, value);
      } 
    }

     
  
    return (
      <FormControl>
        <input
         {...importedProps}
          
          type="file"
          accept="image/*"
          onChange={changeValue} 
        />
        
        
      </FormControl>
    );
  }


export default React.memo(withFormsy(FileInput));
