import * as Actions from 'app/store/actions/fuse';

const initialState = {
	state: { open: false, path: null }
};

const dialogPdf = function(state = initialState, action) {
	switch (action.type) {
		case Actions.OPEN_DIALOG_PDF: {
			return {
				state: action.payload
			};
		}

		default: {
			return state;
		}
	}
};

export default dialogPdf;
