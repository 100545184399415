import React from "react";
import _ from "@lodash";
import TextField from "@material-ui/core/TextField";
import { addValidationRule, withFormsy } from "formsy-react";

function TextFieldFormsy(props) {
  addValidationRule("isLessThan", function (values, value, maxValue) {
    const val1 =
      typeof value === "string"
        ? parseFloat(value.replace(",", "."))
        : value;
    const val2 =
      typeof values[maxValue] === "string"
        ? parseFloat(values[maxValue].replace(",", "."))
        : values[maxValue];
    return val1 && val1 !== "" ? Number(val1) <= Number(val2) : true;
  });
  addValidationRule("isMoreThan", function (values, value, minValue) {
    return value && value !== ""
      ? Number(value) >= Number(values[minValue.min])
      : true;
  });
  addValidationRule(
    "isMoreThanValue",
    function (values, value, minValue) {
      const val1 =
        typeof value === "string"
          ? parseFloat(value.replace(",", "."))
          : value;
      const val2 = minValue.min;
      return value && value !== "" ? val1 >= val2 : true;
    }
  );
  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "className",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "id",
    "inputlabelprops",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "multiline",
    "name",
    "onBlur",
    "onChange",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
    "margin",
    "InputLabelProps"
  ]);

  const { errorMessage, setValue, onChange } = props;
  const value = props.value || "";

  function changeValue(event) {
    let value = event.currentTarget.value;
    if (props.type === "number") {
      if (value === "") value = null;
      else value = Number(value);
    }
    setValue(value);
    if (onChange) {
      onChange(event, value);
    }
  }

  return (
    <TextField
      {...importedProps}
      onChange={changeValue}
      value={value}
      error={Boolean(
        (!props.isPristine && props.showRequired) || errorMessage
      )}
      helperText={errorMessage}
    />
  );
}

export default React.memo(withFormsy(TextFieldFormsy));
