import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "productsApp", fr_CA);
i18next.addResourceBundle("en-CA", "productsApp", en_CA);

export const productsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "products",
  auth: "item_management",
  routes: [
    {
      path: "/app/products/item/:itemId",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/products/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/products",
      component: () => <Redirect to="/app/products/list" />,
    },
  ],
};
