import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { Page, pdfjs, Document } from 'react-pdf';
import { pdfjs } from "react-pdf/dist/umd/entry.webpack";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import * as Actions from "app/store/actions";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { getBase64File } from "app/services/axios/portal/adminDocs.service";
import { FuseLoading } from "@fuse";
// import fileType from "file-type";

import { showMessage, downloadFile } from "app/store/actions";

import { CloudDownload } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  noData: {
    textAlign: "center",
    padding: "60px 20px",
    color: "#aaaaaa",
    background: "#f9f9f9",
  },
  noDataIcon: {
    display: "inline-flex",
    background: "#f1f1f1",
    borderRadius: "100%",
    padding: "5px 15px 15px 15px",
    marginBottom: "30px",
    "& svg": {
      fontSize: "7em",
      margin: "15px",
    },
  },
  noDataTitle: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "1.6em",
    marginBottom: "10px",
  },
}));

function PdfView() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector(({ fuse }) => fuse.dialogPdf.state.open);
  const path = useSelector(({ fuse }) => fuse.dialogPdf.state.path);
  const file = useSelector(({ fuse }) => fuse.dialogPdf.state.file);
  const isProject = useSelector(
    ({ fuse }) => fuse.dialogPdf.state.project
  );
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState(null);
  const [type, setType] = useState("application/pdf");
  const [actionLoading, setActionLoading] = useState([false, 0]);
  const detectMimeType = async (b64) => {
    if (b64) {
      //   const mimeInfo = await fileType.fromBuffer(
      //     Buffer.from(b64, "base64")
      //   );
      //   if (mimeInfo) setType(mimeInfo.mime);
      //   else setType(true);
      setBase64(b64);
    } else {
      dispatch(
        showMessage({
          message: t(`error.emptyFile`),
          autoHideDuration: 4000,
          variant: "error",
        })
      );
      handleCLose();
    }
    setLoading(false);
  };

  const handleCLose = () => {
    dispatch(Actions.openDialogPdf({ open: false }));
  };

  useEffect(() => {
    setLoading(true);
    if (path) {
      getBase64File({ path, isProject })
        .then((res) => {
          detectMimeType(res.base64File);
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            showMessage({
              message: t(`error.displayFile`),
              autoHideDuration: 4000,
              variant: "error",
            })
          );
          handleCLose();
        });
    } else {
      setLoading(false);
      setType(true);
    }
  }, [path]);

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={handleCLose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent style={{ height: "100vh", width: "70vw" }}>
        {!type || loading ? (
          <FuseLoading
            style={{ marginTop: "40vh" }}
            message={t("loading_document")}
          />
        ) : (
          <object
            type={type.toString()}
            width="100%"
            height="100%"
            data={`data:${type};base64,${base64}`}
          >
            {type !==
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              <div className={classes.noData}>
                <Typography
                  align="center"
                  variant="h2"
                  className={classes.noDataTitle}
                >
                  {t("projectsApp:no_data")}
                </Typography>
              </div>
            ) : (
              <div className={classes.noData}>
                <Typography
                  align="center"
                  variant="h2"
                  className={classes.noDataTitle}
                >
                  {t("noDisplayWordFile")}
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={
                    actionLoading[0] ? (
                      <CircularProgress size={24} color="secondary" />
                    ) : (
                      <CloudDownload />
                    )
                  }
                  onClick={() => {
                    setActionLoading([true, 0]);
                    dispatch(
                      downloadFile(file || { path }, t, () =>
                        setActionLoading([false, 0])
                      )
                    );
                  }}
                >
                  {t("clickToDownload") + " "}
                </Button>
              </div>
            )}
          </object>
        )}
      </DialogContent>
    </Dialog>
  );
}
export default PdfView;
