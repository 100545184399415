import { getBase64File } from "app/services/axios/portal/adminDocs.service";
import { showMessage } from "./message.actions";

const handleResponseDownload = (
  { response, error, empty },
  t,
  dispatch,
  stopActionLoading
) => {
  let variant = "success";
  let download = "download";
  if (error) {
    variant = "error";
  }
  if (empty) download = "emptyFile";
  stopActionLoading();
  dispatch(
    showMessage({
      message: t(`${variant}.${download}`),
      autoHideDuration: 4000,
      variant, //success error info warning null
    })
  );
};

export function downloadFile(file, t, stopActionLoading) {
  return (dispatch, getState) => {
    if (!file) {
      handleResponseDownload(
        { error: true },
        t,
        dispatch,
        stopActionLoading
      );
      return;
    }

    getBase64File({ path: file.path })
      .then(async (res) => {
        if (res.base64File) {
          const ext = file.path.split(".")[1];
          let a = document.createElement("a");
          a.href = "data:" + file.type + ";base64," + res.base64File;
          a.download = file.name + "." + ext;
          a.click();

          handleResponseDownload(
            { response: true },
            t,
            dispatch,
            stopActionLoading
          );
        } else {
          handleResponseDownload(
            { error: true, empty: true },
            t,
            dispatch,
            stopActionLoading
          );
        }
      })
      .catch(() => {
        handleResponseDownload(
          { error: true },
          t,
          dispatch,
          stopActionLoading
        );
      });
  };
}
