import UserSystem from "./user-system/";
import { OptionsPageConfig } from "./misc/option/OptionsConfig";
import CoreModules from "./core";
import AdminHome from "./home";
import EmailTemplateModule from "./email-template";
import DenonciationDoc from "./core/denounciation-document";

export const AdminConfigs = [
  ...UserSystem,
  ...CoreModules,
  // Misc
  OptionsPageConfig,
  EmailTemplateModule,
  DenonciationDoc,
  // Home !important to be last
  AdminHome,
];
