import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, gql } from "@apollo/client";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import * as Actions from "app/store/actions";
import LoadingComponent from "@catu/components/LoadingComponent";
import ErrorComponent from "@catu/components/Error";

const UPDATE_LANGUAGE = gql`
  mutation Mutation($data: UserEditInput!) {
    updateUser(data: $data) {
      id
      language
    }
  }
`;

const gQlQuery = gql`
  query Query {
    me {
      id
      language
    }
  }
`;

const languages = [
  {
    id: "fr-CA",
    title: "Français (CA)",
    shortName: "FR",
    flag: "ca",
  },
  { id: "en-CA", title: "English (CA)", shortName: "EN", flag: "ca" },
];

function LanguageSwitcher() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { i18n } = useTranslation();
  const [menu, setMenu] = useState(null);
  const { data, loading, error } = useQuery(gQlQuery);
  const [update] = useMutation(UPDATE_LANGUAGE);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  let currentLng = languages.find((lng) => {
    return lng.id === i18n.language;
  });

  const userMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setMenu(null);
  };

  function handleLanguageChange(lng) {
    const newLangDir = i18n.dir(lng.id);
    let userLanguage;
    const language = lng.id;
    if (language === "fr-CA") {
      userLanguage = "fr_CA";
    } else {
      userLanguage = "en_CA";
    }
    const userData = {
      id: data?.me.id,
      language: userLanguage,
    };
    update({ variables: { data: userData } });
    i18n.changeLanguage(lng.id);

    localStorage.setItem("selectedLanguage", lng.id);

    currentLng = lng.id;
    if (newLangDir !== theme.direction) {
      dispatch(Actions.setDefaultSettings({ direction: newLangDir }));
    }

    userMenuClose();
  }
  return (
    <>
      <Button className="h-64 w-64" onClick={userMenuClick}>
        <img
          className="mx-4 min-w-20"
          src={`assets/images/flags/${currentLng.flag}.png`}
          alt={currentLng.shortName}
        />

        <Typography className="mx-4 font-600">
          {currentLng.shortName}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {languages.map((lng) => (
          <MenuItem
            key={lng.id}
            disabled={i18n.language === lng || loading}
            onClick={() => handleLanguageChange(lng)}
          >
            <ListItemIcon className="min-w-40">
              <img
                className="min-w-20"
                src={`assets/images/flags/${lng.flag}.png`}
                alt={lng.title}
              />
            </ListItemIcon>
            <ListItemText primary={lng.title} />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
