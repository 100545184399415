import React from "react";
import i18next from "i18next";
import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "dashboard", fr_CA);
i18next.addResourceBundle("en-CA", "dashboard", en_CA);

export const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Tableau de bord",
  auth: "login",
  routes: [
    {
      path: "/dashboard/details/top10Customers",
      component: React.lazy(() => import("./views/Top10Customers")),
    },
    {
      path: "/dashboard/details/top10Vendors",
      component: React.lazy(() => import("./views/Top10Vendors")),
    },
    {
      path: "/dashboard/details/projects",
      component: React.lazy(() => import("./views/Projects")),
    },
    {
      path: "/dashboard/details/converted-projects",
      component: React.lazy(() =>
        import("./views/ConvertedProjects")
      ),
    },
    {
      path: "/dashboard",
      component: React.lazy(() => import("./views/Dashboard")),
    },
  ],
};
