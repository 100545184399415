import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "app/auth/store/actions";
import { Link } from "react-router-dom";
import adminMenuHelper from "app/main/admin/home/helper";
import { useTranslation } from "react-i18next";

function UserMenu() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [userMenu, setUserMenu] = useState(null);
  const isAdmin = Boolean(adminMenuHelper().length > 0);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };
  const apiURL = process.env.REACT_APP_BACKEND_URL;
  const imageURl = apiURL + "/" + user.data.photoURL;
  return (
    <React.Fragment>
      <div></div>
      <Button className="h-64" onClick={userMenuClick}>
        {user.data.photoURL ? (
          <Avatar
            className=""
            alt={user.data.displayName}
            src={user.data.photoURL}
            //src={imageURl}
          />
        ) : (
          <Avatar className="">{user.data.displayName[0]}</Avatar>
        )}

        <div className="hidden md:flex flex-col ml-12 items-start">
          <Typography
            component="span"
            className="normal-case font-600 flex"
          >
            {user.data.displayName}
          </Typography>
          <Typography
            className="text-11 normal-case"
            color="textSecondary"
          >
            {user.data?.level?.name
              ? t(`access:role_names.${user.data.level.name}`)
              : ""}
          </Typography>
        </div>

        <Icon
          className="text-16 ml-12 hidden sm:flex"
          variant="action"
        >
          keyboard_arrow_down
        </Icon>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <React.Fragment>
            <MenuItem component={Link} to="/login">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("login")} />
            </MenuItem>
            <MenuItem component={Link} to="/register">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText
                className="pl-0"
                primary={t("register")}
              />
            </MenuItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MenuItem
              component={Link}
              to="/users/profile"
              onClick={userMenuClose}
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText
                className="pl-0"
                primary={t("myprofile")}
              />
            </MenuItem>
            {isAdmin ? (
              <MenuItem
                component={Link}
                to="/admin/home"
                onClick={userMenuClose}
              >
                <ListItemIcon className="min-w-40">
                  <Icon>settings</Icon>
                </ListItemIcon>
                <ListItemText
                  className="pl-0"
                  primary={t("administration")}
                />
              </MenuItem>
            ) : (
              <div></div>
            )}
            <MenuItem
              onClick={() => {
                dispatch(authActions.logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText className="pl-0" primary={t("logout")} />
            </MenuItem>
          </React.Fragment>
        )}
      </Popover>
    </React.Fragment>
  );
}

export default UserMenu;
