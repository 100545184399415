import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "projectsApp", fr_CA);
i18next.addResourceBundle("en-CA", "projectsApp", en_CA);

export const ProjectsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Projects",
  auth: "project_management",
  routes: [
    {
      path: "/app/projects/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/projects/item/send/:itemId",
      component: React.lazy(() => import("./views/SendEmail")),
    },
    {
      path: "/app/projects/item/:itemId",
      component: React.lazy(() => import("./views/Item")),
    },
    {
      path: "/app/projects/item",
      component: () => <Redirect to="/app/projects/item/new" />,
    },
    {
      path: "/app/projects",
      component: () => <Redirect to="/app/projects/list" />,
    },
  ],
};
