const LoginData = `
  user {
    id
    firstName
    email
    lastName
    role {
      accesses {
        can_create
        can_delete
        can_edit
        can_view
        can_view_own
        id
        slug
      }
      name
      id
    }
    profileImage
    signature
  }
`;

export default LoginData;
