import { handleException } from "@catu/helpers/error.helpers";
import axios from "axios";
import { format } from "date-fns";
// import axiosService from '../../libs/axiosService';
const apiURL = process.env.REACT_APP_BACKEND_URL;

export async function getAll() {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(
        `${apiURL}/document/getAllDocuments`,
        {},
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        if (Array.isArray(data.list)) {
          resolve(data.list);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}
export async function getAllAdminDocuments() {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .get(
        `${apiURL}/document/getAdminDocuments`,
        {},
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}
export async function getById(id) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(
        `${apiURL}/document/getById`,
        { id },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}
export async function getDocumentByName(name) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(
        `${apiURL}/document/getByName`,
        { name },
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}
// export async function createUploadFile(item) {
//   let formData = new FormData();
//   formData.append("document", item.file);
//   let today = new Date(item.expiration);
//   let dd = String(today.getDate()).padStart(2, "0");
//   let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
//   let yyyy = today.getFullYear();
//   let dateP = dd + "-" + mm + "-" + yyyy;

//   return new Promise((resolve, reject) => {

const token = window.localStorage.getItem("jwt_access_token");
//     axios
//       .post(`${apiURL}/file/upload/document/create/`, formData, {
//         params: {
//           name: item.name,
//           expiration: "dateP",
//           type: "pdf",
//           isAdmin: true,
//         },
//       })
//       .then(({ data }) => {
//         if (data) {
//           resolve(data);
//         } else {
//           reject("error.generic_error");
//         }
//       })
//       .catch((e) => {
//         handleException(e);
//         reject("error.server_catch");
//       });
//   });
// }
export async function updateUploadFile(item) {
  let formData = new FormData();
  formData.append("document", item.file);

  let today = new Date(item.expiration);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let dateP = dd + "-" + mm + "-" + yyyy;
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(`${apiURL}/file/upload/document/update/`, formData, {
        params: {
          id: item.id,
          expiration: dateP,
          name: item.name,
          type: "pdf",
          isAdmin: true,
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}

export async function updateVersion(item) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(`${apiURL}/document/updateVersion`, item, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}
export async function create(item) {
  const formData = new FormData();
  // const today = new Date(item.expiration);
  // const dd = String(today.getDate()).padStart(2, "0");
  // const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  // const yyyy = today.getFullYear();
  //const dateP = dd + "-" + mm + "-" + yyyy;
  formData.append("file", item.file);
  formData.append("name", item.name);
  formData.append("expiration", item.expiration);
  formData.append("type", "pdf");
  formData.append("isAdmin", true);

  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(`${apiURL}/document/uploadFile`, formData, {
        headers: {
          authorization: "Bearer " + token,
          "content-type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        reject("error.server_catch");
      });
  });
}

export async function archive(item) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(`${apiURL}/document/archive`, item, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}

export async function update(item) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    const formData = new FormData();
    const dateP = format(new Date(item.expiration), "MM-dd-yyyy");
    formData.append("file", item.file);
    formData.append("name", item.name);
    formData.append("expiration", dateP);
    formData.append("type", "pdf");
    formData.append("isAdmin", true);
    axios
      .post(`${apiURL}/document/update?id=${item.id}`, formData, {
        headers: {
          authorization: "Bearer " + token,
          "content-type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject("error.server_catch");
      });
  });
}

//not in use
export async function downloadFile(fileName) {
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios({
      method: "GET",
      url: `${apiURL}/file/downloadFile/${fileName}`,
      responseType: "blob",
    })
      // axios
      // 	.get(
      // 		`${apiURL}/file/downloadFile/${fileName}`,

      // 		{
      // 			headers: {
      // 				'Content-Type': 'application/pdf'
      // 			}
      // 		}
      // 	)
      .then((res) => {
        // if (res) {
        // 	resolve(res);
        // } else {
        // 	reject('error.generic_error');
        // }
      })
      .catch((e) => {
        handleException(e);
        reject(e);
      });
  });
}
export async function getBase64File(params) {
  const { isProject } = params;
  delete params.isProject;
  const uri = isProject
    ? `${apiURL}/file/getByPathInProject`
    : `${apiURL}/file/getByPath`;
  return new Promise((resolve, reject) => {
    const token = window.localStorage.getItem("jwt_access_token");
    axios
      .post(uri, params)
      .then(({ data }) => {
        if (data) {
          resolve(data);
        } else {
          reject("error.generic_error");
        }
      })
      .catch((e) => {
        handleException(e);
        reject(e);
      });
  });
}
