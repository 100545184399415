import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "adminDocsApp", fr_CA);
i18next.addResourceBundle("en-CA", "adminDocsApp", en_CA);

export const AdminDocsConfig = {
  settings: {
    layout: {
      config: { loadingMessage: "test", displayLoading: true },
    },
  },
  name: "AdministratorAttachments",
  auth: "admin_documents_management",
  routes: [
    {
      path: "/app/documents/item/:itemId/:itemSlug?",
      component: React.lazy(() => import("./views/Document")),
    },
    {
      path: "/app/documents/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/documents",
      component: () => <Redirect to="/app/documents/list" />,
    },
  ],
};
