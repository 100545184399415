import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FuseAnimate } from "@fuse";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Typography, Card, CardContent } from "@material-ui/core";
import ForgetPasswordForm from "./sub-component/form";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.dark,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: theme.palette.primary.contrastText,
  },
  title: {
    textTransform: "uppercase",
  },
}));

function ForgotPasswordPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [bgImage] = React.useState(
    "assets/images/backgrounds/bkg_1.jpg"
  );

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32"
      )}
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="flex flex-col items-center justify-center w-full">
        <FuseAnimate animation="transition.expandIn">
          <Card className="w-full max-w-384">
            <CardContent className="flex flex-col items-center justify-center p-32">
              <div className="w-128 m-32">
                <img src="assets/images/logos/logo.svg" alt="Logo" />
              </div>

              <Typography
                variant="h6"
                className={"mt-16 mb-32 " + classes.title}
              >
                {t("forgot_password_question")}
              </Typography>

              <ForgetPasswordForm />

              <div className="flex flex-col items-center justify-center pt-32 pb-24">
                <Link className="font-medium" to="/login">
                  {t("back_to_login_page")}
                </Link>
              </div>
            </CardContent>
          </Card>
        </FuseAnimate>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
