import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import fr_CA from './i18n/fr_CA';
import en_CA from './i18n/en_CA';

i18next.addResourceBundle('fr-CA', 'emailApp', fr_CA);
i18next.addResourceBundle('en-CA', 'emailApp', en_CA);

export const EmailConfig = {
	settings: {
		layout: {
			config: { loadingMessage: 'test', displayLoading: true }
		}
	},
	name: 'EmailConfig',
	auth: 'login',
	routes: [
		{
			path: '/app/emails/item/:itemId/:itemSlug?',
			component: React.lazy(() => import('./views/EmailConfigPage'))
		},
		{
			path: '/admin/email-templates/',
			component: React.lazy(() => import('./views/List'))
		},
		{
			path: '/app/emails',
			component: () => <Redirect to="/admin/email-templates/" />
		}
	]
};
