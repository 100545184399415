import React, { useState, useEffect } from "react";
import _ from "@lodash";
import TextField from "@material-ui/core/TextField";
import { addValidationRule, withFormsy } from "formsy-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { green } from "@material-ui/core/colors";

function TextFieldObservable(props) {
  addValidationRule("isExist", function (values, value, existValue) {
    return existValue !== "exist";
  });
  addValidationRule(
    "serverError",
    function (values, value, serverError) {
      return serverError;
    }
  );
  addValidationRule(
    "isMargedValue",
    function (values, value, minMax) {
      if (!value || value === "") return true;
      const min = minMax.min || 0;
      const max = minMax.max || Infinity;
      return value >= min && value <= max;
    }
  );

  const importedProps = _.pick(props, [
    "autoComplete",
    "autoFocus",
    "children",
    "onKeyPress",
    "className",
    "classes",
    "defaultValue",
    "disabled",
    "FormHelperTextProps",
    "fullWidth",
    "id",
    "InputLabelProps",
    "inputProps",
    "InputProps",
    "inputRef",
    "label",
    "margin",
    "multiline",
    "name",
    "onBlur",
    "onChange",
    "onKeyUp",
    "onFocus",
    "placeholder",
    "required",
    "rows",
    "rowsMax",
    "select",
    "SelectProps",
    "type",
    "variant",
    "loading",
    "error",
    "loadingMessage",
    "style",
  ]);
  const [values] = useState(() => new Subject());
  const value = props.value || "";

  function changeValue(event) {
    props.setValue(event.currentTarget.value);
    values.next(event.currentTarget.value);
  }

  useEffect(() => {
    const sub = values
      .pipe(debounceTime(props.time))
      .subscribe((val) => {
        if (props.isValidValue(val) || !props.withValidation)
          props.onChange(val);
      });
    return () => sub.unsubscribe();
  }, [props.time, values]);

  function handleBlur(event) {
    props.setValue(event.target.value);
    if (props.onBlur) {
      props.onBlur(event.target.value);
    }
  }

  return (
    <div className={props.height ? props.height : ""}>
      <TextField
        {...importedProps}
        onChange={changeValue}
        onBlur={handleBlur}
        value={value}
        error={Boolean(
          (!props.isPristine && props.showRequired) ||
            props.error ||
            props.errorMessage
        )}
        helperText={
          props.error || (
            <p className="break-all">{props.errorMessage}</p>
          )
        }
        inputProps={{ ...importedProps.inputProps }}
        InputProps={{
          endAdornment: props.loading ? (
            <CircularProgress
              style={{
                width: "25px",
                height: "25px",
              }}
            />
          ) : (
            !props.showRequired &&
            !props.showError &&
            props?.validations?.isExist === "notExist" &&
            !props.errorMessage && (
              <CheckCircleOutlineIcon style={{ color: green[500] }} />
            )
          ),
          ...importedProps.InputProps,
        }}
      />
    </div>
  );
}

export default React.memo(withFormsy(TextFieldObservable));
