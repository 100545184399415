const navigationConfig = [
  {
    id: "portal-component",
    translate: "portal",
    type: "group",
    children: [
      {
        id: "dashboard-component",
        translate: "dashboard",
        type: "item",
        icon: "dashboard",
        url: "/dashboard",
        auth: "login",
      },
      {
        id: "projects-component",
        translate: "projects",
        type: "item",
        icon: "ballot",
        url: "/app/projects",
        auth: "project_management",
      },
      {
        id: "customers-component",
        translate: "customers",
        type: "item",
        icon: "person",
        url: "/app/customers",
        auth: "customers_management",
      },
    ],
  },
  {
    id: "management-component",
    translate: "management",
    type: "group",
    children: [
      {
        id: "factories-component",
        translate: "factories",
        type: "collapse",
        icon: "store",
        url: "/app/factories",
        auth: "factory_management",
        children: [
          {
            id: "formulas-component",
            translate: "formulas",
            type: "item",
            icon: "colorize",
            url: "/app/formulas",
            auth: "formula_management",
          },
        ],
      },
      {
        id: "admin-docs-component",
        translate: "docAdmin",
        type: "item",
        icon: "description",
        url: "/app/documents",
        auth: "admin_documents_management",
      },
      {
        id: "suppliers-component",
        translate: "supplier",
        type: "collapse",
        icon: "shopping_cart",
        url: "/app/suppliers",
        auth: "suppliers_management",
        children: [
          {
            id: "materials-component",
            translate: "materials",
            type: "item",
            icon: "listalt",
            url: "/app/materials",
            auth: "material_management",
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
