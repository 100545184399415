import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import fr_CA from './i18n/fr_CA';
import en_CA from './i18n/en_CA';

i18next.addResourceBundle('fr-CA', 'suppliersApp', fr_CA);
i18next.addResourceBundle('en-CA', 'suppliersApp', en_CA);

export const SuppliersConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	name: 'Suppliers',
	auth: 'login',
	routes: [
		{
			path: '/app/suppliers/item/:itemId/:itemSlug?',
			component: React.lazy(() => import('./views/Supplier'))
		},
		{
			path: '/app/suppliers/list',
			component: React.lazy(() => import('./views/List'))
		},
		{
			path: '/app/suppliers',
			component: () => <Redirect to="/app/suppliers/list" />
		}
	]
};
