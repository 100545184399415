import FuseUtils from "@fuse/FuseUtils";
import i18n from "i18next";

function adminMenuHelper() {
  const data = [];
  const row_data = [
    {
      id: "users-access",
      title: i18n.t("users-access"),
      elements: [
        {
          id: "admin-users-access-users-manager",
          title: i18n.t("admin-users-access-users-manager"),
          icon: "supervised_user_circle",
          url: "/admin/users/list",
          auth: "user_management",
        },
        {
          id: "admin-users-access-accesses",
          title: i18n.t("admin-users-access-users-rights-management"),
          auth: "access_management",
          icon: "supervised_user_circle",
          url: "/admin/user-management/accesses/",
        },
      ],
    },
    {
      id: "general-settings",
      title: i18n.t("general-settings"),
      elements: [
        {
          id: "divisions-component",
          title: i18n.t("divisions-component"),
          type: "item",
          icon: "storage",
          url: "/app/divisions",
          auth: "division_management",
        },
        {
          id: "categories-component",
          title: i18n.t("categories-component"),
          type: "item",
          icon: "category",
          url: "/app/categories",
          auth: "category_management",
        },
        {
          id: "items-component",
          title: "Items",
          type: "item",
          icon: "shoppingbasket",
          url: "/app/items",
          auth: "item_management",
        },
        {
          id: "product-component",
          title: i18n.t("product_plural"),
          type: "item",
          icon: "shoppingbasket",
          url: "/app/products/list",
          auth: "item_management",
        },
        {
          id: "measurementUnits-component",
          title: i18n.t("measurementUnits-component"),
          type: "item",
          icon: "aspect_ratio",
          url: "/app/measurementUnits/list",
          auth: "item_management",
        },
        {
          id: "email-component",
          title: "Configuration Emails",
          type: "Item",
          icon: "alternateemail",
          url: "/app/emails",
          auth: "config",
        },
        {
          id: "denounciation-document-component",
          title: i18n.t("config"),
          type: "item",
          // icon: "FindInPage",
          url: "/admin/misc/options",
          auth: "config",
        },
      ],
    },
  ];

  // Verify for the Permission
  for (let category of row_data) {
    if (category.elements && category.elements.length > 0) {
      let tmp = [];
      for (let item of category.elements) {
        if (FuseUtils.hasPermission(item.auth)) {
          tmp.push(item);
        } else {
          // tmp.push({...item, url: ''});
        }
      }
      if (tmp.length > 0) {
        category.elements = tmp;
        data.push(category);
      }
    }
  }

  return data;
}

export default adminMenuHelper;
