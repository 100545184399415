import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
// import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "denounciationDocumentApp", fr_CA);
// i18next.addResourceBundle("en-CA", "denounciationDocumentApp", en_CA);

export const denounciationDocumentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "denounciationDocumentConfig",
  // auth: "admin_documents_management",
  routes: [
    {
      path: "/admin/denounciation-document/",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/denounciation",
      component: () => (
        <Redirect to="/admin/denounciation-document/" />
      ),
    },
  ],
};
export default denounciationDocumentConfig;
