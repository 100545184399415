import { DashboardConfig } from "./dashboard/DashboardConfig";
import { DivisionsConfig } from "./divisions/PageConfig";
import { FactoriesConfig } from "./factories/PageConfig";
import { SuppliersConfig } from "./suppliers/PageConfig";
import { CustomersConfig } from "./customers/PageConfig";
import { ItemsConfig } from "./items/PageConfig";
import { AdminDocsConfig } from "./administrative documents/PageConfig";
import { MaterialsConfig } from "./materials/PageConfig";
import { CategoriesConfig } from "./categories/PageConfig";
import { FormulasConfig } from "./formulas/PageConfig";
import { ProjectsConfig } from "./projects/PageConfig";
import { EmailConfig } from "./emailConfigurations/PageConfig";
import { productsConfig } from "./products";
import { measurementUnitsConfig } from "./measurementUnits/PageConfig";
import { denounciationDocumentConfig } from "../admin/core/denounciation-document";

export const PortalConfigs = [
  DashboardConfig,
  DivisionsConfig,
  FactoriesConfig,
  SuppliersConfig,
  CustomersConfig,
  ItemsConfig,
  AdminDocsConfig,
  MaterialsConfig,
  CategoriesConfig,
  FormulasConfig,
  ProjectsConfig,
  EmailConfig,
  productsConfig,
  measurementUnitsConfig,
  denounciationDocumentConfig,
];
