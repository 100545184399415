import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import fr_CA from './i18n/fr_CA';
import en_CA from './i18n/en_CA';

i18next.addResourceBundle('fr-CA', 'categoriesApp', fr_CA);
i18next.addResourceBundle('en-CA', 'categoriesApp', en_CA);

export const CategoriesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	name: 'Categories',
	auth: 'login',
	routes: [
		{
			path: '/app/categories/item/:itemId/:itemSlug?',
			component: React.lazy(() => import('./views/Category'))
		},
		{
			path: '/app/categories/list',
			component: React.lazy(() => import('./views/List'))
		},

		{
			path: '/app/categories',
			component: () => <Redirect to="/app/categories/list" />
		}
	]
};
