import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "customersApp", fr_CA);
i18next.addResourceBundle("en-CA", "customersApp", en_CA);

export const CustomersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Customers",
  auth: "customers_management",
  routes: [
    {
      path: "/app/customers/item/:itemId/",
      component: React.lazy(() => import("./views/Customer")),
    },
    {
      path: "/app/customers/yearly-agreement/list/:itemId/",
      component: React.lazy(() =>
        import("./views/YearlyAgreementList")
      ),
    },
    {
      path: "/app/customers/yearly-agreement/item/:itemId/:listId?",
      component: React.lazy(() => import("./views/YearlyAgreement")),
    },

    {
      path: "/app/customers/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/customers",
      component: () => <Redirect to="/app/customers/list" />,
    },
  ],
};
