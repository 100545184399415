import React from "react";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/fr_CA";

i18next.addResourceBundle("fr-CA", "emailConfig", fr_CA);
i18next.addResourceBundle("en-CA", "emailConfig", en_CA);

export default {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Gestionnaire des template de courriels",
  // auth: "platform-admin",
  routes: [
    {
      path: "/admin/email-templates/:id?/:slug?",
      component: React.lazy(() => import("./views/List")),
    },
  ],
};
