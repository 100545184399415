import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';

import fr_CA from './i18n/fr_CA';
import en_CA from './i18n/en_CA';

i18next.addResourceBundle('fr-CA', 'materialsApp', fr_CA);
i18next.addResourceBundle('en-CA', 'materialsApp', en_CA);

export const MaterialsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	name: 'Materials',
	auth: 'login',
	routes: [
		{
			path: '/app/materials/item/:itemId/:itemSlug?',
			component: React.lazy(() => import('./views/Material'))
		},
		{
			path: '/app/materials/list',
			component: React.lazy(() => import('./views/List'))
		},
		{
			path: '/app/materials',
			component: () => <Redirect to="/app/materials/list" />
		}
	]
};
