import React from "react";
import { Redirect } from "react-router-dom";
import i18next from "i18next";

import fr_CA from "./i18n/fr_CA";
import en_CA from "./i18n/en_CA";

i18next.addResourceBundle("fr-CA", "factoryApp", fr_CA);
i18next.addResourceBundle("en-CA", "factoryApp", en_CA);

export const FactoriesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  name: "Factories",
  auth: "factory_management",
  routes: [
    {
      path: "/app/factories/item/:itemId/:itemSlug?",
      component: React.lazy(() => import("./views/Form")),
    },
    {
      path: "/app/factories/default-price/:itemId",
      component: React.lazy(() => import("./views/DefaultPrice")),
    },
    {
      path: "/app/factories/list",
      component: React.lazy(() => import("./views/List")),
    },
    {
      path: "/app/factories",
      component: () => <Redirect to="/app/factories/list" />,
    },
  ],
};
